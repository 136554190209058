@import "rsuite/dist/rsuite.css";

.menu-sub {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease; 
}

.menu-item.open .menu-sub {
  max-height: 500px; 
}

#parsley-id-5, #parsley-id-11, #parsley-id-7, #parsley-id-9, #parsley-id-15 {
  margin: 0px;
}


