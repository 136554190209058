/* Scrollable container */
/* #recordsContainer {
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f7f7f7;
} */

/* Collapsible container */
.record {
  background-color: white;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.collapsible {
  background-color: #7367f0;
  color: white;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 18px;
  border-radius: 5px;
}


.collapsible:hover {
  background-color: #7367f0;
}

.content {
  
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: white;
}

.content.show {
  max-height: 100%; 
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

table, th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

.btn-print {
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #7367f0;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.btn-print:hover {
  background-color: #7367f0;
}
